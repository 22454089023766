<template>
	<main class="home">
		<alerts section-title="Alerts" />
		<slideshow
			:slideshow-id="1"
			template="SlideshowHeroSlider"
			section-title="Featured Slider"
			:count="5"
		/>
		<div
			class="row max-width-grid padding-12 bp-1024:padding-t-72 bp-1024:padding-b-40 bp-1440:padding-x-0"
		>
			<slideshow
				:slideshow-id="3"
				template="SlideshowFeature"
				section-title="Featured Slideshow"
				class="col-1-of-1 bp-1024:padding-r-24 bp-1024:col-2-of-3"
				:count="1"
			/>
			<stories
				class="col-1-of-1 padding-t-12 bp-1024:padding-t-0 bp-1024:padding-l-24 bp-1024:col-1-of-3"
				template="StoriesList"
				section-title="Stories"
				:breakpoints="{
					0: { count: 4, skip: 0, template: 'StoriesList' },
					1024: { count: 7, skip: 0, template: 'StoriesList' },
				}"
			/>
		</div>
		<slideshow
			class="border bg-color-surface row display-block max-width-grid padding-12 bp-1024:padding-y-32 bp-1440:padding-x-24"
			:slideshow-id="2"
			template="SlideshowBubblesSlider"
			section-title="Featured"
			:count="4"
		/>
		<slideshow
			class="padding-t-12 bp-1024:padding-t-72 bp-1440:padding-x-0"
			:slideshow-id="5"
			template="SlideshowPanels"
			section-title="Our Priorities"
			:count="3"
			full-height
		/>
		<content-stream
			template="ContentStreamPackery"
			section-title="Connect With Us"
			:dummy="true"
			:breakpoints="{
				0: { count: 4, skip: 0, template: 'ContentStreamPackery' },
				1024: { count: 10, skip: 0, template: 'ContentStreamPackery' },
			}"
		></content-stream>
		<!--<slideshow
			class="row display-block max-width-grid padding-12 bp-1024:padding-y-72 bp-1440:padding-x-0"
			:slideshow-id="1"
			template="SlideshowButtonLinks"
			section-title="Student-Athletes"
			:dummy="true"
		/>-->
		<div class="border-t">
			<slideshow
				class="row display-block max-width-grid padding-12 bp-1024:padding-y-72 bp-1440:padding-x-0"
				:slideshow-id="4"
				template="SlideshowCardSlider"
				section-title="Resources"
				:count="6"
			/>
		</div>
	</main>
</template>

<script>
import Alerts from '@/components/Alerts';
import Slideshow from '@/components/slideshow/Slideshow';
import Stories from '@/components/stories/Stories';
import ContentStream from '@/components/contentstream/ContentStream';

export default {
	name: 'Home',

	components: {
		Alerts,
		Slideshow,
		Stories,
		ContentStream,
	},

	created() {
		this.setMetaSidearm();
	},
};
</script>
